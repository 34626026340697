<template>
    <div class="box my-account">
        <div class="buyCouponPay">
            <el-form :model="shopCouponsData" label-width="120px" :rules="rules" ref="shopCouponsData">
                <el-form-item label="订单号：">
                    {{query.order_no}}
                </el-form-item>
                <el-form-item label="应付金额：">
                    {{query.total_price}}
                </el-form-item>
                <el-form-item label="支付凭证：" prop="pay_prove">
                    <el-upload
                        :action="uploadActionUrl"
                        list-type="picture-card"
                        :on-preview="handlePictureCardPreview"
                        :limit="5"
                        :on-success="handleAvatarSuccess"
                        :on-exceed="handleExceed"
                        :file-list="fileList"
                        :before-upload="beforeAvatarUpload"
                        :on-remove="handleRemove">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog append-to-body :visible.sync="showImg">
                        <img width="100%" :src="dialogImageUrl" alt="">
                    </el-dialog>
                </el-form-item>
                <el-form-item label="备注：">
                    <el-input type="textarea" maxlength="300" v-model="shopCouponsData.user_remark"></el-input>
                </el-form-item>
                <el-form-item label="">
                    <el-button type="primary" @click="subBuyData('shopCouponsData')">提交申请</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="mt30">
            <div class="panel">
                对公转账必填信息
            </div>
            <div class="panel">
                <pre>
                  开户名称：{{$store.getters.accountInfo.value.bank_account_name}}
                  开户银行：{{$store.getters.accountInfo.value.bank_name}}
                  账号：{{$store.getters.accountInfo.value.bank_account_no}}
                </pre>
            </div>
            <div class="alert-info alert">
                <div v-html="$store.getters.accountInfo.value.content">
                </div>
            </div>
        </div>

    </div>
</template>

<script>

    import Config from "@/utils/config"
    import {voucherOrderPay} from '@/api/member/member'
    export default {
        name: "",
        data() {
            return {
                dialogImageUrl: "",
                showImg: false,
                uploadActionUrl: Config.baseUrl + "/api/upload/headimg",// 图片上传地址
                shopCouponsData: {
                    order_no: "",
                    pay_prove: [],// 支付凭证
                    user_remark: ""// 用户备注
                },
                fileList: [],
                query: {},
                rules: {
                    pay_prove: [
                        { required: true, message: "请上传支付凭证", trigger: "blur" }
                    ]
                }
            }
        },
        props: {},
        mixins: [],
        watch: {},
        computed: {},
        created() {
            this.query = this.$route.query
            this.$store.dispatch("member/sysBankAccountDat")
        },
        mounted() {

        },
        methods: {
            subBuyData(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                      this.voucherOrderPayData()
                    } else {
                        return false
                    }
                })
            },
            // 提交支付凭证
            voucherOrderPayData() {
                let par = {
                    order_no: this.query.order_no,
                    pay_prove: this.shopCouponsData.pay_prove,
                    user_remark: this.shopCouponsData.user_remark
                }
                voucherOrderPay(par).then(res => {
                    this.$router.push({
                        path: '/member/subSuccess'
                    })
                })
            },
            handleRemove(file, fileList) {
                let list = []
                fileList.map(item => {
                    if (item.response) {
                        list.push(item.response.data.pic_path)
                    } else {
                        list.push(item.url)
                    }
                })
                this.shopCouponsData.pay_prove = list
            },
            beforeAvatarUpload(file) {
                const isJPG = file.type === "image/jpeg" || "image/png"
                const isLt2M = file.size / 1024 / 1024 < 10

                if (!isJPG) {
                    this.$message.error("上传图片只能是 JPG ,png格式!")
                }
                if (!isLt2M) {
                    this.$message.error("上传图片大小不能超过 10MB!")
                }
                return isJPG && isLt2M
            },
            handleExceed(files, fileList) {
                this.$message.warning(`当前限制选择 5 个文件!`)
            },
            handleAvatarSuccess(res, file) {
                let data = res.data
                this.shopCouponsData.pay_prove.push(data.pic_path)
            },
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url
                this.showImg = true
            }
        }
    }
</script>

<style scoped type="text/scss" lang="scss">
  .my-account {
    background: #ffffff;
    padding: 50px 20px;
    font-size: 16px;
  }

  .buyCouponPay {
    width: 550px;
    ::v-deep .el-upload--picture-card {
      width: 100px;
      height: 100px;
      line-height: 98px;
    }
    ::v-deep .el-upload-list--picture-card .el-upload-list__item{
      width: 100px;
      height: 100px;
    }
  }

</style>
